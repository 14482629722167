<template>
  <!-- 商城广告详情 -->
  <moe-page title="商城广告详情">
    <moe-form v-if="bannerData.id" :showBack="false" :showClose="true">
      <el-form-item label="广告图">
        <moe-image :src="bannerData.coverUrl" width="100px" height="100px"></moe-image>
      </el-form-item>
      <el-form-item label="广告位置">
        {{ $moe_format.getBannerType(bannerData.type) }}
      </el-form-item>
      <el-form-item label="跳转类型">
        {{ $moe_format.getAdvertForm(bannerData.form) }}
      </el-form-item>
      <template v-if="bannerData.form === 1">
        <el-form-item label="站外跳转链接">
          {{ bannerData.link }}
        </el-form-item>
      </template>
      <template v-if="bannerData.form === 2">
        <el-form-item label="内部跳转地址">
          {{ $moe_format.getAdvertLink(bannerData.link) }}
        </el-form-item>
        <el-form-item v-if="$moe_data.navigateToIsValidator.includes(bannerData.link)" label="编号">
          {{ bannerData.activityId }}
        </el-form-item>
      </template>
      <el-form-item label="排序">
        {{ bannerData.sort }}
      </el-form-item>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsAdvertDetail',
  data() {
    return {
      bannerData: {},
    };
  },
  mounted() {
    this.$moe_api.BANNER_API.bannerDetail({ id: this.$route.query.id }).then((data) => {
      if (data.code == 200) {
        let { form, link } = data.result;
        let newLink = '', activityId = '';
        if (form === 2 && link.indexOf('?') > -1 && this.$moe_data.navigateToIsValidator.includes(link.split('?')[0])) { // N元任选活动
          newLink = link.split('?')[0]
          activityId = this.$moe_formatter.getUrlParams(link).id;
        } else {
          newLink = link
        }
        this.bannerData = {
          ...data.result,
          link: newLink,
          activityId
        };
      } else {
        this.$moe_msg.error(data.message);
      }
    });
  },
};
</script>
